// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

// Components
import { Link } from 'react-router-dom';
import Input from '../form/Input';

// Misc
import * as v from '../../modules/validators';

class LoginForm extends Component {

  render() {
    const { handleSubmit, valid, submitting } = this.props;

    return (
      <form
        className="FlexForm"
        onSubmit={handleSubmit}
      >
        <div className="Fieldset">
          <Field
            label="Email"
            name="email"
            component={Input}
            type="email"
            validate={[v.required, v.email]}
          />

          <Field
            label="Password"
            name="password"
            component={Input}
            type="password"
            validate={[v.required]}
          />

          <div className="FormRow">
            <button
              className="Button u-w100 u-mt1"
              type="submit"
              disabled={!valid || submitting}
            >
              <span className="u-w100 u-textCenter">Log In</span>
            </button>
            <div className="u-mt1 u-text-sm u-blockCenter u-textCenter">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  valid: PropTypes.bool,
  submitting: PropTypes.bool
};

export default reduxForm({ form: 'login' })(LoginForm);

