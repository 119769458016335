// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import LoginForm from './LoginForm';
import Meta from '../shared/Meta';

// Actions
import { userLogin, userOAuthLogin } from '../../actions/authActions';

import styles from '../../../stylesheets/public/layouts/Register.module.postcss';

class Login extends Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data) {
    this.props.userLogin(data, this.props.history);
  }

  render() {
    const { auth } = this.props;

    return (
      <div className={`${styles.Login} u-textCenter u-blockCenter u-limitWidth35 u-mb3 u-mt3`}>
        <Meta noindex />
        <h1 className="u-mb3">Log In</h1>
        <div className="Box Box--white">
          <div>
            {auth.loginFailed &&
              <div className="FormMessages FormMessages--error">
                <span className="icon icon-warning" />
                <h4>
                  {auth.message}
                </h4>
              </div>
            }

            <div className="u-mb1 u-textLeft">
              <LoginForm onSubmit={this.onFormSubmit} />
            </div>
            <hr className={styles.OrRule} />
            <div className="u-mb1">
              <button
                className={"Button Button--outlined u-w100 " + styles.Button__facebook}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'facebook',
                    params: {
                      needs_user_type: true
                    }
                  }, this.props.history);
                }}
              >
                <img src='/static/images/icons/facebook-logo-secondary.png'/>
                <span>Login with Facebook</span>
              </button>

              <button
                className={`Button Button--outlined u-w100 ${styles.Button__google} u-ml0 u-mt1`}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'google',
                    params: {
                      needs_user_type: true
                    }
                  }, this.props.history);
                }}
              >
                <img src='/static/images/icons/google.svg'/>
                <span>Sign in with Google</span>
              </button>

              <button
                className={"Button u-w100 u-mt1 " + styles.Button__apple}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'apple',
                    params: {
                      needs_user_type: true
                    }
                  }, this.props.history);
                }}
              >
                <img src="/static/images/appleid_button.png" height="48"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object,
  route: PropTypes.object,
  userLogin: PropTypes.func.isRequired,
  userOAuthLogin: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: (data, history) => {
      dispatch(userLogin(data, history));
    },
    userOAuthLogin: (provider, history) => {
      dispatch(userOAuthLogin(provider, history));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

